(<template>
  <section class="finished-assignment"
           :class="type">
    <div class="finished-assignment__icon"
         :class="[icon]"></div>
    <p class="finished-assignment__text">{{ title }}</p>
    <p class="finished-assignment__description">
      {{ description }}
    </p>
  </section>
</template>)

<script>

  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style scoped>
  .finished-assignment {
    display: block;
    width: 100%;
    padding: 40px 10px 30px 10px;
    text-align: center;
  }

  .finished-assignment__text {
    margin-top: 10px;
    font-size: 22px;
  }

  .finished-assignment__description {
    margin-top: 5px;
    font-weight: bold;
    font-size: 14px;
  }

  .finished-assignment__icon {
    width: 100px;
    height: 100px;
    margin: 10px auto;
    background-size: 100%;
  }

  /***** Success Type  ****/
  .finished-assignment.success {
    background-color: #008489;
  }

  .finished-assignment.success .finished-assignment__text {
    color: #fff;
  }

  .finished-assignment.success .finished-assignment__description {
    color: #fff;
  }

  /***** Information Type  ****/
  .finished-assignment.information {
    background-color: #f0e8dd;
  }

  .finished-assignment.info .finished-assignment__text {
    color: #000;
  }

  .finished-assignment.info .finished-assignment__description {
    color: #000;
  }

  /***** Icons  ****/
  .finished-assignment__icon.tick {
    background-image: url(~@assets/imgs/feedback_icons/feedback_sent_white.svg);
  }

  .finished-assignment__icon.info {
    background-image: url(~@assets/imgs/undefined_imgs/info-icon-thin.svg);
  }

  .finished-assignment__description:not(:last-child) {
    margin-bottom: 15px;
  }

  @media (max-width: 767px) {
    .finished-assignment {
      padding-top: 100px;
      padding-bottom: 150px;
    }
  }
</style>
